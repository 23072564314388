//----------------------------------------------------------------------------
// Utilities: Background Color
//----------------------------------------------------------------------------

.bg {
    &--light {
        background-color: palette(neutral, white) !important;
    }

    &--medium {
        background-color: palette(neutral, athens-gray) !important;
    }

    &--dark {
        background-color: palette(brand, blue-mid) !important;
    }

    &--accent {
        background-color: palette(brand, maroon) !important;
    }

    &--image-light {
        background-color: palette(neutral, white) !important;
        background-image: url('../../media/images/background-theme.png');
        background-repeat: repeat;
        background-size: contain;
    }

    &--image-medium {
        background-color: palette(neutral, athens-gray) !important;
        background-image: url('../../media/images/background-theme.png');
        background-repeat: repeat;
        background-size: contain;
    }

    &--image-dark {
        background-color: palette(brand, blue-mid) !important;
        background-image: url('../../media/images/background-theme.png');
        background-repeat: repeat;
        background-size: contain;
    }

    &--image-accent {
        background-color: palette(brand, maroon) !important;
        background-image: url('../../media/images/background-theme.png');
        background-repeat: repeat;
        background-size: contain;
    }
}
