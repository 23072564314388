//---------------------------------------------------------------------
// Text
//---------------------------------------------------------------------

// Based on the Style Guide
// https://www.sketch.com/s/be2d23a6-ec21-4e2b-86fe-f101ad043921/a/Wm0rqq
.txt {
    font-family: font(family, base);
    font-weight: font(weight, light);
    font-size: remify($base-font-size);
    line-height: remify($base-line-height);
}

%txt--link {
    font-family: inherit;
    font-weight: inherit;
    text-decoration: underline;
}

.txt--link {
    @extend %txt--link;
    color: palette(brand, blue-light);
}

.txt--link-inverse {
    @extend %txt--link;
    color: palette(neutral, white);
}

.txt--link-dark {
    @extend %txt--link;
    color: palette(brand, blue-dark);
}

.txt--link-error {
    @extend %txt--link;
    color: palette(status, error);
}

.txt--color-white {
    color: palette(neutral, white);
}

.txt--color-blue-light {
    color: palette(brand, blue-light);
}

.txt--color-blue-mid {
    color: palette(brand, blue-mid);
}

.txt--color-blue-dark {
    color: palette(brand, blue-dark);
}

.txt--color-mine-shaft {
    color: palette(neutral, mine-shaft);
}

.txt--color-fedora {
    color: palette(neutral, fedora);
}

.txt--weight-light {
    font-weight: font(weight, light);
}

.txt--weight-regular {
    font-weight: font(weight, regular);
}

.txt--weight-medium {
    font-weight: font(weight, medium);
}

.txt--weight-bold {
    font-weight: font(weight, bold);
}

.txt--italic {
    font-style: italic;
}

.txt--align-right {
    text-align: right;
}

.txt--14 {
    font-size: remify(14);
}

.txt--16 {
    font-size: remify(16);
}

.txt--18 {
    font-size: remify(18);
}

.txt--36 {
    font-size: remify(36);
}

.txt--18-24\@medium {
    font-size: remify(18);

    @include bpMedium() {
        font-size: remify(24);
    }
}

.txt--md {
    @include scaleFontSize(16, 20);
}

.txt--lg {
    @include scaleFontSize(16, 24);
}

.txt--loose {
    line-height: 1.57;
}

.txt--line-height-2 {
    line-height: 2;
}

.txt--uppercase {
    text-transform: uppercase;
}

.txt--capitalize {
    text-transform: capitalize;
}

.txt--center {
    text-align: center;
}
