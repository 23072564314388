.Image {
    display: block;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    margin: 0;

    &::before {
        float: left;
        padding-top: 56.25%;
        content: '';
    }

    &::after {
        display: block;
        content: '';
        clear: both;
    }

    &__img {
        @include objectFit(cover);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        box-sizing: border-box;
        padding: 0;
        border: none;
        margin: auto;
        display: block;
        width: 0;
        height: 0;
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
    }
}
