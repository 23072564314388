//---------------------------------------------------------------------
// Jump Nav Layout Section
//
// Limits the width of a section where a jump-nav lives to account for the
// width of the jump-nav
//---------------------------------------------------------------------

.jump-nav-layout {
    @include bpLarge() {
        max-width: calc(100% - #{$jump-nav-width + $base-spacing-unit});
    }
}
